<template>
  <p-container class="no-bg">  
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
      <a-button type="primary" @click="addData">新增</a-button>
      <a-button type="primary" @click="handledelet">删除</a-button>
      <!-- <a-button type="primary">批量分类梳理</a-button>
      <a-button type="primary">申请号批量查询</a-button>
      <a-button type="primary">启动评审</a-button> -->
    </template>
    
    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
    <a
      href="javascript:;"
      @click="webShowModal(col)"
      slot="webAction"
      slot-scope="text, col"
      >编辑</a
    >
     
      
    </a-table>    
    <!--目标地址集构建-->
    <a-modal v-model="webVisible" title="网址编辑" @ok="handleOk">
        <div>
        <a-input-group>
          <a-row :gutter="2">
              <a-col :span="24">
                <a-form-item label="网址标注">
                   <a-input v-model="mbdz.urlname" :disabled="false"/>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="搜索网址">
                   <a-input v-model="mbdz.url" :disabled="false"/>
                </a-form-item>
              </a-col>
          </a-row>
          
       </a-input-group>
        </div>
      <div>
      </div>
    </a-modal>
    
  </p-container> 
</template>
<script>
import {getAllmessage,updatemessage,deletemessage, addzjkUrl} from '../../../../../api/result'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
     
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
     localStorage.removeItem("dzArry")
      localStorage.setItem("dzArry",JSON.stringify(selectedRows))
      
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
     localStorage.removeItem("dzArry")
     localStorage.setItem("dzArry",JSON.stringify(selectedRows))
     
  },
};
const columns = [
  {
    title: "",
    dataIndex: "edit",
    width: "10%",
    scopedSlots: { customRender: "webAction" },
  },
  {
    title: "网址标注",
    dataIndex: "urlname",
  },
  {
    title: "搜索网址",
    dataIndex: "url",
  },
];
const dataSource = [
        {
          key: "0",
          edit: "编辑",
          web: "XXX科技部",
          searchWeb: "www.XXXX.com",
        },
]
export default {
    name:'QuickSearch',
    data() {
      return {
        loading: false,
        treeData: [],
        rowSelection,
        dataSource,
        columns,
        webVisible:false,
        mbdz:'',
        isAdd: false
      }
    },
    mounted(){
      getAllmessage()
      .then(res=>{
        console.log(res.data)
        this.dataSource=res.data
      })
      .catch((e) => {})
      .catch((e) => {});
    },
      computed: {
      type(){
          return this.$route.name.substr(this.$route.name.lastIndexOf("_")+1);
      },
      // showLeft(){
      //   return this.type!='all'
      // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
    methods: {
      addData() {
        this.webVisible = true;
        this.isAdd = true
        this.mbdz= {}
      },
      initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
      webShowModal(col) {
        this.isAdd = false
        this.webVisible = true;
        this.mdl = { ...col }
        console.log(col)
        this.mbdz=col
      },
      handleOk(e) {
        this.webVisible = false;
        if (this.isAdd) {
          console.log(this.mbdz);
          addzjkUrl(this.mbdz)
            .then(res => {
              console.log(res);
            })
        } else {
          // console.log(e);
          this.webVisible = false;
          console.log(this.mbdz)
          updatemessage(this.mbdz)
          .then(res=>{
            console.log(res)
            this.dataSource=res.data
          })
        }
      },
      handledelet(){
        deletemessage(JSON.parse(localStorage.getItem("dzArry")))
        .then(res=>{
          console.log(res)
          this.dataSource=res.data
        })
      }
    }
 
}
</script>

<style scoped>
</style>>